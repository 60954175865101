<template>
  <b-modal
    id="csv-upload-modal"
    hide-footer
    centered
    title="Add New SIM"
    size="lg"
    :scrollable="true"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="map-view-model srt67 modal-add-sensors no-header-modal modal-add-sensors-v1"
  >
    <div class="tab-new-ui">
      <div class="slect-account-title-center">Upload CSV</div>
      <div class="sensor-modal-scroll">
        <div>
          <div class="sensors-sc mb-2">
            <div class="formbold-main-wrapper">
              <div class="formbold-form-wrapper">
                <form action="https://formbold.com/s/FORM_ID" method="POST">
                  <div class="mb-6">
                    <div class="formbold-mb-5 formbold-file-input">
                      <input type="file" name="file" id="file" />
                      <label for="file">
                        <div>
                          <CSVIcon />
                          <b-form-file
                            class="custom-file-input"
                            v-model="inputCsvFile"
                            @input="() => importCsv()"
                          />
                          <span class="formbold-drop-file mt-2">
                            Select a CSV file to upload
                          </span>
                        </div>
                      </label>
                    </div>
                    <div class="formbold-file-list formbold-mb-5">
                      <div class="formbold-file-item">
                        <span class="formbold-file-name">
                          banner-design.csv
                        </span>
                        <button>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                      <div class="formbold-progress-bar">
                        <div class="formbold-progress"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center save-btn-footer">
            <button class="formbold-btn w-75">Export File</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BAvatar,
  BTooltip,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormFile
} from "bootstrap-vue";
import CSVIcon from "@/assets/images/icons/SIM-icon/CSVIcon.svg";
import vSelect from "vue-select";
import DatePicker from "./DatePicker.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    BCard,
    BButton,
    VBTooltip,
    BFormInput,
    vSelect,
    BAvatar,
    BTooltip,
    BCol,
    BRow,
    DatePicker,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    CSVIcon,
    BFormFile
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return { inputCsvFile: [] };
  },
  methods: {
    importCsv(range = false) {
      // this.csvFile = this.$refs.doc.files[0];
      try {
        if (this.inputCsvFile && this.inputCsvFile.name.includes(".csv")) {
          const reader = new FileReader();
          reader.onload = (res) => {
            this.parseRangeCsv(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.inputCsvFile);
        }
        setTimeout(() => {
          this.inputCsvFile = [];
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
    parseRangeCsv(content) {
      try {
        // this.ranges = content
        //   .split("\n")
        //   .map((row) => row.split(/[;,]/)) // Split on either ";" or ","
        //   .map((row) => {
        //     return {
        //       x: row[0].trim(), // Trim the x value
        //       y: row[1] ? row[1].trim() : "", // Trim the y value if it exists
        //       z: row[2] ? row[2].trim() : "" // Trim the z value if it exists
        //     };
        //   })
        //   .filter((r) => r.x || r.y || r.z);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss">
.srt67 {
  .modal-title {
    display: none;
  }
  .modal-header {
    background-color: transparent !important;
  }

  .close {
    margin: -1.3rem -1.4rem -0.8rem auto !important;
    box-shadow: 0px 4px 24px 0px rgba(34, 41, 47, 0.1) !important;
  }
}

.formbold-mb-5 {
  margin-bottom: 20px;
}
.formbold-pt-3 {
  padding-top: 12px;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 48px 30px 48px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  // background: white;
}
.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.formbold-form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  // background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: var(--primary);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}

.formbold-file-input input {
  opacity: 0;
  cursor: pointer !important;
  position: absolute;
}
.formbold-file-input {
  cursor: pointer !important;
}
.formbold-file-input label {
  position: relative;
  border: 2px dashed var(--primary);
  border-radius: 6px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
}
.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: var(--primary);
  font-size: 20px;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: var(--primary);
  display: block;
  margin-bottom: 8px;
}
.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 16px 32px;
}

.formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formbold-file-item button {
  color: #07074d;
  border: none;
  background: transparent;
  cursor: pointer;
}

.formbold-file-name {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  padding-right: 12px;
}
.formbold-progress-bar {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background: #e2e5ef;
}

.formbold-progress {
  position: absolute;
  width: 75%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary);
  border-radius: 8px;
}

.dark-layout {
  .formbold-file-list {
    border-radius: 6px;
    background: #333e55;
    padding: 16px 32px;
  }

  .formbold-file-name {
    color: white;
  }
  .formbold-file-item button {
    color: white;
  }
}

@media (min-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
}

@media only screen and (max-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
  .formbold-main-wrapper {
    padding: 0px !important;
    padding-top: -10px !important;
  }
  .sensor-modal-scroll .sensors-sc {
    height: calc(100vh - 420px) !important;

    overflow: auto;
  }
  .modal-body {
    min-height: 100% !important ;
  }
}
</style>
