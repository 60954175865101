<template>
  <div>
    <!-- Modal Form -->
    <b-modal
      :id="rechargeModelName"
      hide-footer
      centered
      size="lg"
      v-model="showModal"
      :scrollable="true"
      :hide-header="true"
      no-close-on-esc
      no-close-on-backdrop
      modal-class="map-view-model min-date-picker-height calender-overflow-visible modal-add-sensors no-header-modal modal-add-sensors-v1"
    >
      <div style="padding-top: 10px" class="tab-new-ui">
        <div class="slect-account-title-center">{{ $t("sim.recharge") }}</div>

        <div>
          <div class="sensors-re">
            <div class="">
              <validation-observer v-slot="{ invalid }">
                <b-row>
                  <b-col md="6" sm="12">
                    <validation-provider
                      name="Plan"
                      :rules="{ required: true, min: 3, max: 50 }"
                      v-slot="{ errors }"
                    >
                      <b-form-group :label="$t('sim.plans')">
                        <b-form-input
                          v-model="recharge.plan"
                          :placeholder="$t('Enter Plans')"
                          name="plans"
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" sm="12">
                    <validation-provider
                      name="Amount"
                      :rules="{
                        required: true,
                        min_value: 0,
                        regex: /^\d+(\.\d+)?$/
                      }"
                      v-slot="{ errors }"
                    >
                      <b-form-group :label="$t('sim.amount')">
                        <b-form-input
                          type="number"
                          v-model="recharge.amount"
                          :placeholder="$t('Enter amount')"
                          name="amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" sm="12">
                    <validation-provider
                      name="Last recharge Validity"
                      :rules="{ required: true, min: 1 }"
                      v-slot="{ errors }"
                    >
                      <b-form-group :label="$t('sim.Validity')">
                        <b-input-group size="md" append="Days">
                          <b-form-input
                            v-model="recharge.expiry_date"
                            :placeholder="$t('Enter Validity')"
                            name="Last rechargeValidity"
                            trim
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" sm="12">
                    <b-form-group label="Select recharge Date">
                      <validation-provider
                        name="Last recharge Date"
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <DatePicker
                          v-model="recharge.recharge_date"
                          :options="{
                            single_date: true,
                            format: 'date_format'
                          }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="d-flex justify-content-center save-btn-footer">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1"
                    :disabled="invalid"
                    @click="createRechargeHistory"
                  >
                    {{ $t("sim.recharge_save") }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    type="reset"
                    class="mt-2"
                    @click="cancelModal"
                  >
                    {{ $t("sim.recharge_cancel") }}
                  </b-button>
                </div>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Table Component -->
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup
} from "bootstrap-vue";
import DatePicker from "./DatePicker.vue";
import RechargeHistoryTable from "./RechargeHistory.vue"; // Ensure you're using the correct import
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import Ripple from "vue-ripple-directive";
import DeviceService from "@/libs/api/device-service";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      showModal: false,
      isEditable: true,
      unitReAllocateTo: [],
      isFetchingOptions: false,
      recharge: {
        activateDate: null,
        plan: "",
        amount: "",
        expiry_date: 0,

        ctime: "",
        recharge_date: null
      },
      items: [], // Array to store table data
      // fields: [
      //   { key: "plan", label: "Plans" },
      //   { key: "validity", label: "Validity (Days)" },
      //   { key: "amount", label: "Amount" },
      //   { key: "recharge_date", label: "Recharge Date" },
      //   { key: "ACTIONS", label: "Actions" }
      // ],
      currentPage: 1,
      currentPerPage: 10
    };
  },
  components: {
    DatePicker,
    RechargeHistoryTable,
    BButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    AreYouSureModal,
    ToastificationContent
  },
  props: ["selectedRow", "refreshed", "rechargeModelName"],
  directives: {
    Ripple
  },
  watch: {
    selectedRow(val) {
      if (val) {
        this.recharge.activateDate = this.selectedRow.activateDate;
        this.recharge.plan = this.selectedRow.plan;
        this.recharge.amount = this.selectedRow.amount;
        this.recharge.expiry_date = moment(this.selectedRow.expiry_date).diff(
          moment(this.selectedRow.recharge_date),
          "days"
        );
        console.log("1", this.recharge.expiry_date);
        this.recharge.sim_id = this.selectedRow.sim_id || this.$route.params.id;
        this.recharge.recharge_date = this.selectedRow.recharge_date;
      }
    }
  },
  mounted() {
    if (this.selectedRow) {
      this.recharge.activateDate = this.selectedRow.activateDate;
      this.recharge.plan = this.selectedRow.plan;
      this.recharge.amount = this.selectedRow.amount;
      if (this.selectedRow.ctime) {
        this.recharge.expiry_date = this.selectedRow.expiry_date.diff(
          this.selectedRow.recharge_date,
          "days"
        );
        console.log("2", this.recharge.expiry_date);
      }
      this.recharge.sim_id = this.selectedRow.sim_id || this.$route.params.id;
      this.recharge.recharge_date = this.selectedRow.recharge_date;
    }
  },
  methods: {
    async createRechargeHistory(account, status) {
      try {
        this.show = true;
        let requestData = {
          account_id:
            this.selectedAccount || localStorage.getItem("USER_ACCOUNT_ID"),
          recharge_date: this.recharge.recharge_date,
          expiry_date: moment(this.recharge.recharge_date)
            .add(this.recharge.expiry_date, "days")
            .format(),
          amount: this.recharge.amount,
          plan: this.recharge.plan,
          sim_ids: this.recharge.sim_id
        };
        if (Array.isArray(this.recharge.sim_id)) {
          requestData.sim_ids = this.recharge.sim_id;
        } else {
          requestData.sim_ids = [this.recharge.sim_id];
        }
        if (this.selectedRow && this.selectedRow.id) {
          requestData.id = this.selectedRow.id;
        }
        let response;
        if (requestData.id) {
          response = await new DeviceService().updateRechargeHistory({
            ...requestData,
            sim_id: requestData.sim_ids[0]
          });
        } else {
          response = await new DeviceService().createRechargeHistory(
            requestData
          );
        }

        if (response && response.data) {
          this.showModal = false;
          this.refreshed();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "",
              text: this.$t("sim.RechargeAddedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    cancelModal() {
      // this.resetForm();
      // this.refreshed();
      this.$bvModal.hide(this.rechargeModelName);
    },
    resetForm() {
      this.refreshed();
      // this.recharge = {
      //   activateDate: null,
      //   plan: "",
      //   amount: "",
      //   expiry_date: "",
      //   expiry_date: "",
      //   recharge_date: null
      // };
    }
  }
};
</script>
<style lang="scss">
.calender-overflow-visible {
  .modal-body {
    overflow: visible;
  }
}
@media screen and (max-width: 990px) {
  .min-date-picker-height {
    .modal-content {
      min-height: 80%;
    }
  }
}
</style>
