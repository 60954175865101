<template>
  <div>
    <div class="group-card-row23 mb-1" v-if="!groupName">
      <div
        class="group-card group-card-mobile mt-1"
        @click="statusFilters(child.key)"
        v-for="child in test"
        :key="child.key"
      >
        <div class="card" style="cursor: pointer">
          <div
            class="card-body d-flex justify-content-between align-items-center p-1"
          >
            <b-avatar
              size="50"
              variant="light-secondary"
              class="icon-mobile-view"
              pills
            >
              <component :is="child.icon"></component>
            </b-avatar>

            <div class="truncate group-truncate">
              <span class="group-name group-name-active"
                >{{ child.label }}
              </span>
              <h2 class="mb-25 font-weight-bolder group-name-active">
                {{ child.count }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alerts-search-comp">
      <b-card class="alert-search position-relative main-card-padding pb-table">
        <div class="m-2-br-alert-bottm">
          <div class="adduser-btn-row">
            <h4 class="card-title">
              {{ $t("sim.SIMs") }}
            </h4>
            <div class="filter-btn-outer-new d-flex" v-if="!groupName">
              <b-button
                v-b-tooltip.hover.v-primary
                :title="$t('sim.Import')"
                variant="primary"
                class="btn-icon desktop-hidden mr-1"
                @click="openFileDialog"
              >
                <ImportCSV />
              </b-button>
              <b-button
                v-b-tooltip.hover.v-primary
                :title="$t('sim.AddSim')"
                variant="primary"
                class="btn-icon desktop-hidden mr-1"
                @click="redirectOnCreate"
              >
                <feather-icon icon="PlusIcon" size="12" />
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="desktop-hidden"
                @click="showFiltermenu = !showFiltermenu"
              >
                <feather-icon icon="FilterIcon" size="15" />
              </b-button>
            </div>
            <div
              class="d-flex flex-wrap filter-card"
              v-if="showFiltermenu && !groupName"
            >
              <div class="alert-per-page" v-if="!isGroupEntity">
                <div class="addXIcon">
                  <feather-icon
                    class="sizeIcon"
                    @click="showFiltermenu = !showFiltermenu"
                    icon="XIcon"
                    size="15"
                  />
                </div>

                <label class="search-label mt-1">{{
                  $t("Notification.Entries")
                }}</label>
                <v-select
                  :clearable="false"
                  id="vue-select"
                  class="per-page-selector d-inline-block fix-83"
                  v-model="perPage"
                  :options="option"
                />
              </div>
              <div class="sim-account-selection">
                <AccountSearch
                  all="true"
                  class="unit-search-v2"
                  @input="getDashboard()"
                  v-model="selectedAccount"
                />
              </div>
              <search
                v-if="!isGroupEntity"
                style="float: left"
                class="search-comp no-btm-0"
                v-model="searchFiled"
                :searchColumn="searchColumn"
                :operator="operator"
              ></search>

              <b-button
                v-b-tooltip.hover.v-primary
                variant="primary"
                class="mobile-hidden mr-1"
                @click="openFileDialog"
              >
                <ImportCSV />
                <span class="mobile-hidden ml-1">{{ $t("sim.Import") }}</span>
              </b-button>
              <b-button
                v-b-tooltip.hover.v-primary
                variant="primary"
                class="mobile-hidden"
                @click="redirectOnCreate"
              >
                <feather-icon icon="PlusIcon" size="12" />
                <span class="mobile-hidden">{{ $t("sim.AddNewSIM") }}</span>
              </b-button>
            </div>
          </div>
        </div>
        <CsvUploadModal ref="csvUploadModal" v-if="!groupName" />
        <simList
          :searchFiled="searchFiled"
          :perPage="perPage"
          :groupName="groupName"
          @getDashboard="getDashboard"
          :selectedAccount="selectedAccount"
        ></simList>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BButton,
  VBTooltip,
  BAvatar,
  BTooltip,
  BLink,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback
} from "bootstrap-vue";
import SimCreate from "./SimCreate.vue";
import simList from "@/views/Sim/SimList.vue";
import constants from "@/utils/constants";
import vSelect from "vue-select";
import Search from "../Common/search.vue";
import AllIcon from "@/assets/images/icons/SIM-icon/all.svg";
import ImportCSV from "@/assets/images/icons/SIM-icon/importCSV.svg";
import ActiveIcon from "@/assets/images/icons/SIM-icon/active.svg";
import InActiveIcon from "@/assets/images/icons/SIM-icon/inactive.svg";
import AvailableIcon from "@/assets/images/icons/SIM-icon/available.svg";
import OverdueIcon from "@/assets/images/icons/SIM-icon/overdue.svg";
import DatePicker from "./DatePicker.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CsvUploadModal from "./CsvUpload.vue";
import DeviceService from "@/libs/api/device-service";
import AccountSearch from "../Unit/Unit-V2/AccountSearch.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    VBTooltip,
    Search,
    BFormInput,
    vSelect,
    simList,
    BAvatar,
    BTooltip,
    BLink,
    BCol,
    BRow,
    OverdueIcon,
    AllIcon,
    ActiveIcon,
    InActiveIcon,
    AvailableIcon,
    DatePicker,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    SimCreate,
    CsvUploadModal,
    ImportCSV,
    ToastificationContent,
    AccountSearch
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      fileName: "",
      searchColumn: ["msisdn", "operator", "status"],
      searchFiled: {},
      operator: "ilike",
      perPage: 10,
      option: ["10", "20", "30"],
      selectedAccount:
        this.$route.query.account_id || localStorage.getItem("USER_ACCOUNT_ID"),
      showFiltermenu: false,
      test: [
        {
          key: "all",

          icon: AllIcon,
          count: "0",
          label: this.$t("sim.AllSIMs")
        },
        {
          key: "active",
          label: this.$t("sim.Active"),
          icon: ActiveIcon,
          count: "0"
        },
        {
          key: "inactive",
          label: this.$t("sim.Inactive"),
          icon: InActiveIcon,
          count: "0"
        },
        {
          key: "available",
          label: this.$t("sim.Available"),
          icon: AvailableIcon,
          count: "0"
        },
        {
          key: "overdue",
          label: this.$t("sim.Overdue"),
          icon: OverdueIcon,
          count: "0"
        }
      ]
    };
  },
  props: ["isGroupEntity", "groupName"],
  watch: {},
  mounted() {
    const { perPage } = this.$route.query;
    this.getDashboard();
    if (perPage) {
      this.perPage = parseInt(perPage);
    }
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    statusFilters(status) {
      this.$router.replace({ query: { status: status } });
    },
    openFileDialog() {
      this.$refs.csvUploadModal.$bvModal.show("csv-upload-modal");
    },
    redirectOnCreate() {
      this.$router.push({ name: "sim-create" });
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    async getDashboard() {
      try {
        let response = await new DeviceService().getSimsDashboard({
          account_id: this.selectedAccount
        });

        if (response && response.data) {
          this.show = false;
          const counts = response.data.counts;
          this.test = [
            {
              key: "all",
              icon: AllIcon, // Placeholder for your icon component
              count: counts.total_sims.toString() || 0 || 0, // Total SIMs
              label: this.$t("sim.AllSIMs") // Assuming you use this.$t for translations
            },
            {
              key: "active",
              label: this.$t("sim.Active"),
              icon: ActiveIcon, // Placeholder for your active SIM icon
              count: counts.active_sims.toString() || 0 // Active SIMs
            },
            {
              key: "inactive",
              label: this.$t("sim.Inactive"),
              icon: InActiveIcon, // Placeholder for your inactive SIM icon
              count: counts.inactive_sims.toString() || 0 // Inactive SIMs
            },
            {
              key: "available",
              label: this.$t("sim.Available"),
              icon: AvailableIcon, // Placeholder for your available SIM icon
              count: counts.available_sims.toString() || 0 // Available SIMs
            },
            {
              key: "overdue",
              label: this.$t("sim.Overdue"),
              icon: OverdueIcon, // Placeholder for your overdue SIM icon
              count: counts.overdue_recharge_sims.toString() || 0 // Overdue SIMs
            }
          ];
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.alert-search {
  .alert-per-page {
    margin-left: auto;
    margin-right: 15px;
  }

  .search-filter {
    margin-left: 0;
  }

  .operator-table-width {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .search-filter {
    margin-right: 15px;
  }
}

.sim-info-card {
  .b-avatar {
    .b-avatar-img {
      padding: 4px;

      img {
        object-fit: contain;
      }
    }
  }

  .cust-card-sim {
    display: inline-flex;
    padding: 15px;
    margin-right: 10px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;

    .sim-name-ev-block {
      padding-left: 15px;
      width: 100%;

      .media.event-Tag {
        padding-top: 5px;
      }

      .sim-name-ev-text {
        font-size: 16px;
        font-weight: 500;
        color: var(--sim-bg-dark);
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 87%;
        white-space: nowrap;
      }

      .sim-light-ev-text {
        font-size: 14px;
        min-width: 70%;
        line-height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 255px;
        white-space: nowrap;
      }
    }

    .card-data-ev {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      justify-content: space-between;
    }
  }
}

.dark-layout {
  .cust-card-sim {
    .sim-name-ev-block {
      .sim-name-ev-text {
        color: var(--light);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .dash-width {
    width: 100%;
  }
}

.group-card-row23 {
  display: flex;
  flex-wrap: wrap;
}

.group-avtar {
  display: flex;
  justify-content: start;
  width: 30%;
}

.group-truncate {
  margin: auto;
  text-align: center;
}

.group-name {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
  }
}

.group-card {
  box-shadow: 0px 4px 12px rgba(34, 41, 47, 0.1);
  border-radius: 8px;
  margin-right: auto;
  width: 19%;

  .card {
    margin-bottom: 0px;
    border: 1px solid transparent;
  }
}

.group-card-column .card-body {
  flex-direction: row-reverse !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #6e6b7b;
}

@media only screen and (max-width: 820px) {
  .group-card-row23 {
    .group-card-mobile {
      width: calc(50% - 5px);
      margin-top: 5px !important;
      margin-right: 2.5px !important;
      margin-left: 2.5px !important;
    }

    .icon-mobile-view {
      width: 48px !important;
      height: 48px !important;

      .group-svg {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        /* Safari & Chrome */
        -moz-transform: scale(0.7);
        /* Firefox */
        -ms-transform: scale(0.7);
        /* Internet Explorer */
        -o-transform: scale(0.7);
      }
    }
  }
}

.group-card-row23 .group-card:last-child {
  margin-right: 0 !important;
  /* Adjust the value as needed */
}

@import "@core/scss/vue/libs/vue-select.scss";

.normal-alerts {
  .group-svg {
    path {
      fill: var(--secondary);
    }

    .red-svg {
      fill: var(--danger);
    }

    .device-svg {
      stroke: var(--secondary);

      path {
        fill: none;
        stroke: var(--secondary);
      }
    }
  }
}

.active-alerts {
  .group-svg {
    path {
      fill: var(--primary);
    }

    .red-svg {
      fill: var(--danger);
    }

    .device-svg {
      stroke: var(--primary);

      path {
        fill: none;
        stroke: var(--primary);
      }
    }

    .geo-fn path {
      stroke: var(--primary);

      path {
        fill: none;
        stroke: var(--primary);
      }
    }

    .geo-route-fn path {
      path {
        fill: none;
        stroke: var(--primary);
      }
    }
  }
}

.dark-layout {
  .add-card {
    border: 1px solid #404656;
  }
  .normal-alerts {
    .group-svg {
      path {
        fill: var(--light);
      }

      .red-svg {
        fill: var(--danger);
      }

      .device-svg {
        stroke: var(--light);

        path {
          fill: none;
          stroke: var(--light);
        }
      }
    }
  }

  .active-alerts {
    .group-svg {
      path {
        fill: var(--primary);
      }

      .red-svg {
        fill: var(--danger);
      }

      .device-svg {
        stroke: var(--primary);

        path {
          fill: none;
          stroke: var(--primary);
        }
      }
    }
  }
}
.sim-account-selection {
  width: 15%;
  margin-right: 10px;
  .form-group {
    margin-bottom: 0rem !important;
  }
}
@media screen and (max-width: 991px) {
  .sim-account-selection {
    width: 100%;
    margin-right: 0px;
    .form-group {
      margin-bottom: 1rem !important;
    }
  }
}
</style>
