var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"tab-new-ui",staticStyle:{"padding-top":"10px"}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('div',[_c('h4',{staticClass:"card-title mb-0"},[_vm._v(" "+_vm._s(_vm.action === "create" ? _vm.$t("sim.AddNewSIM") : _vm.action === "edit" ? _vm.$t("sim.UpdateSIM") : _vm.$t("sim.ViewSIM"))+" ")])]),_c('b-button',{staticClass:"add-user-btn v2-back ml-10",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"ArrowLeftIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("sim.Back")))])],1)],1),_c('validation-observer',{ref:"operatorRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('div',{staticClass:"sensors-sc"},[_c('div',{staticClass:"add-card"},[_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"MSISDN Number","rules":{
                    required: true,
                    min: 5,
                    max: 30
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('sim.MSISDN-Number*')}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('sim.MSISDN-Number'),"name":"MSISDN-Number","trim":""},model:{value:(_vm.sim.msisdn),callback:function ($$v) {_vm.$set(_vm.sim, "msisdn", $$v)},expression:"sim.msisdn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Sim Number","rules":{
                    required: true,
                    min: 5,
                    max: 30
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('sim.Sim-Number*')}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('sim.Sim-Number'),"name":"Sim-Number","trim":""},model:{value:(_vm.sim.sim_number),callback:function ($$v) {_vm.$set(_vm.sim, "sim_number", $$v)},expression:"sim.sim_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Operator","rules":{
                    required: true,
                    min: 2,
                    max: 30
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('sim.operator*')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('sim.operator'),"name":"Operator","trim":""},model:{value:(_vm.sim.operator),callback:function ($$v) {_vm.$set(_vm.sim, "operator", $$v)},expression:"sim.operator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('sim.activate_date*')}},[_c('validation-provider',{attrs:{"name":"SIM activate date","rules":{
                      required: true
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('DatePicker',{attrs:{"options":{ single_date: true, format: 'date_format' }},model:{value:(_vm.sim.sim_active_date),callback:function ($$v) {_vm.$set(_vm.sim, "sim_active_date", $$v)},expression:"sim.sim_active_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('div',{staticClass:"add-card"},[_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('unit.Labels'),"disabled":!_vm.isEditable}},[_c('TagMultiSelect',{class:!_vm.isEditable ? 'disabled' : '',model:{value:(_vm.sim.labels),callback:function ($$v) {_vm.$set(_vm.sim, "labels", $$v)},expression:"sim.labels"}})],1)],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Status","rules":{
                    required: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('sim.Status*')}},[_c('v-select',{attrs:{"options":_vm.statusOption,"disabled":!_vm.isEditable,"clearable":false,"placeholder":_vm.$t('sim.selectStatus'),"reduce":function (option) { return option.key; },"label":"label"},model:{value:(_vm.sim.status),callback:function ($$v) {_vm.$set(_vm.sim, "status", $$v)},expression:"sim.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1),(_vm.showRecharge)?_c('div',{staticClass:"add-card"},[_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Plan","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('sim.plans')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Enter Plans'),"name":"plans","trim":""},model:{value:(_vm.sim.plan),callback:function ($$v) {_vm.$set(_vm.sim, "plan", $$v)},expression:"sim.plan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Amount","rules":{
                    required: true,
                    min_value: 0,
                    regex: /^\d+(\.\d+)?$/
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('sim.amount')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('sim.Enter_amount'),"name":"amount","type":"number"},model:{value:(_vm.sim.amount),callback:function ($$v) {_vm.$set(_vm.sim, "amount", $$v)},expression:"sim.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Validity","rules":{
                    required: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('sim.expiry_date*')}},[_c('b-input-group',{attrs:{"size":"md","append":"Days"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('sim.Enter_validity'),"name":"Validity","type":"number","trim":""},model:{value:(_vm.sim.expiry_date),callback:function ($$v) {_vm.$set(_vm.sim, "expiry_date", $$v)},expression:"sim.expiry_date"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('sim.last_recharge*')}},[_c('validation-provider',{attrs:{"name":"Last recharge Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('DatePicker',{attrs:{"options":{
                        single_date: true,
                        format: 'date_format',
                        opens: 'top'
                      }},model:{value:(_vm.sim.recharge_date),callback:function ($$v) {_vm.$set(_vm.sim, "recharge_date", $$v)},expression:"sim.recharge_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-right save-btn-footer"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.saveSim}},[_vm._v(" "+_vm._s(_vm.$t("device.Save"))+" ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }