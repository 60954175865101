<template>
  <div>
    <b-card no-body class="mb-0 alert-notification simlist-view-tab">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="this.currentPerPage || 10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>

      <b-table
        responsive
        show-empty
        sticky-header
        :items="items"
        :fields="tableColumns"
        @row-selected="onRowSelected"
        select-mode="multiple"
        ref="table"
        v-else
      >
        <template #cell(select)="data">
          <b-form-checkbox
            v-model="data.item.selected"
            @change="updateSelected(data.item)"
          ></b-form-checkbox>
        </template>

        <template #head(select)>
          <b-form-checkbox
            :checked="allSelected"
            @change="toggleAllSelection()"
            v-model="isAllSelected"
          ></b-form-checkbox>
        </template>
        <template #cell(recharge)="row">
          <span>{{ `${row.item.plan} - ${row.item.amount}` }} </span>
          <span
            >On
            {{ DateTimeConvert(row.item.recharge_date, constants.DATE_FORMAT) }}
          </span>
        </template>
        <template #cell(labels)="row">
          <div class="parent" id="parent">
            <DynamicBadge
              :isList="true"
              :entityType="'SIM'"
              :tagsData="row.item.labels"
              :showTextInBadge="Number(7)"
              :event="row.item"
              :maxTagShow="Number(defaultBadgeShow)"
              :listApi="getAllSims"
            ></DynamicBadge>
            <b-badge
              class="badge-cust"
              :variant="resolveFilterBadgeColor(row.item.status)"
            >
            </b-badge>
          </div>
        </template>
        <template #cell(ACTIONS)="row">
          <div class="text-left min-80 d-flex justify-content-end">
            <span
              style="padding-right: 15px"
              :id="row.item.allocate_tool"
              v-b-tooltip.hover.top="$t('tooTip.allocation')"
              v-if="
                row.item.status.toLowerCase() === 'active' &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.SIMS
                })
              "
              class="text-primary cursor-pointer"
              @click="showPopUp(row.item)"
            >
              <AllocationIcon class="allocate-icon" />
            </span>

            <span
              class="cursor-pointer"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.RECHARGE_MANAGE,
                  subject: constants.PERMISSIONS_MODEL.SIMS
                })
              "
            >
              <RechargeIcon
                @click="openEditHistory(row.item)"
                v-b-tooltip.hover.top="$t('sim.Recharge')"
                class="allocate-icon"
              />
            </span>
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.SIMS
                })
              "
            >
              <b-link
                :to="{
                  name: 'sim-edit',
                  params: {
                    id: row.item.id
                  }
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.update')"
                  icon="EditIcon"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
              </b-link>
            </span>
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.READ,
                  subject: constants.PERMISSIONS_MODEL.SIMS
                })
              "
            >
              <b-link
                :to="{
                  name: 'sim-info',
                  params: { id: row.item.id }
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.view')"
                  icon="EyeIcon"
                  class="text-primary action-icon cursor-pointe"
                  size="18"
                />
              </b-link>
            </span>
            <span>
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                @click="deleteSim(row.item)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.DELETE,
                    subject: constants.PERMISSIONS_MODEL.SIMS
                  })
                "
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mt-2">
        <b-row>
          <b-col cols="12" sm="7">
            <div
              v-if="selectedItems > 1 || (isAllSelected && selectedItems > 1)"
              class="d-flex align-items-center justify-content-between edit-card-w"
            >
              <div class="mb-b23">
                <span class="ml-1">{{
                  isAllSelected
                    ? "All SIMs Selected"
                    : ` Selected  ${selectedItems} SIMs `
                }}</span>
              </div>
              <div class="d-flex">
                <div class="pr-1" v-for="child in bulkMenu" :key="child.key">
                  <div
                    v-b-tooltip.hover.top="child.tooltip"
                    @click="bulkChange(child.key)"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b-avatar
                      size="40"
                      variant="light-secondary"
                      class="icon-mobile-view edit-icon"
                      pills
                      style="cursor: pointer"
                    >
                      <component :is="child.icon"></component>
                    </b-avatar>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="5"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalSims > 0"
              v-model="currentPage"
              :total-rows="totalSims"
              :per-page="currentPerPage"
              first-number
              last-number
              class="mb-0 mt-0 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="handlePageChange"
              :no-paging-nav="false"
              :active-class="activeClass"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AddRecharge
      ref="AddRecharge2"
      :refreshed="refreshed"
      :selectedRow="selectedRow"
      :rechargeModelName="rechargeModelName"
    />
    <AreYouSureModal
      v-if="modelName"
      :data="{
        ...deleteData,
        modelName: modelName
      }"
      :onClose="onClose"
      :removedUser="handleOperations"
      :customData="customData"
    />
    <SearchCard
      geofenceControl=""
      :searchKey="searchKey"
      @searchCardClose="searchCardClose"
      @selectedLabel="selectedLabel"
      @allocatedAccount="allocatedAccount"
      v-if="['group', 'allocation'].includes(searchKey)"
    ></SearchCard>
    <SimAllocationPopUp
      v-if="isAllocationEnable"
      :allocationData="allocationData"
      :onClose="onClose"
      :changeInput="changeInput"
      :isDevice="true"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BFormCheckbox,
  BSkeletonTable
} from "bootstrap-vue";

import RemoveAlerts from "@/layouts/components/RemoveAlerts.vue";
import UnitService from "@/libs/api/unit-service";
import SimAllocationPopUp from "./SimAllocation.vue";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import Badge from "@/layouts/components/Badge.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import RechargeIcon from "@/assets/images/icons/rechargeIcon.svg";
import DeviceService from "@/libs/api/device-service";
import constants from "@/utils/constants";
import AllocationIcon from "@/assets/images/icons/allocation.svg";
import AllIcon from "@/assets/images/icons/SIM-icon/all.svg";
import GroupIcon from "@/assets/images/icons/SIM-icon/groupIcon.svg";
import ImportCSV from "@/assets/images/icons/SIM-icon/importCSV.svg";
import DeleteIcon2 from "@/assets/images/icons/SIM-icon/delete.svg";
import ActiveIcon from "@/assets/images/icons/SIM-icon/active.svg";
import InActiveIcon from "@/assets/images/icons/SIM-icon/inactive.svg";
import AvailableIcon from "@/assets/images/icons/SIM-icon/available.svg";
import OverdueIcon from "@/assets/images/icons/SIM-icon/overdue.svg";
import { DeleteIcon } from "vue-feather-icons";
import AddRecharge from "./AddRecharge.vue";
import SearchCard from "@/views/Route/SearchCard.vue";
export default {
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      filter = filter && filter.toUpperCase();
      const status = {
        ACTIVE: "light-success",
        INACTIVE: "light-warning",
        DAMAGED: "light-danger",
        BLOCKED: "light-secondary"
      };

      const selectedStatus =
        filter && status[filter] ? status[filter] : status["ACTIVE"];

      return selectedStatus;
    };
    return {
      resolveFilterBadgeColor
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    RemoveAlerts,
    VBTooltip,
    BSkeletonTable,
    DynamicBadge,
    Badge,
    AreYouSureModal,
    BFormCheckbox,
    RechargeIcon,
    SimAllocationPopUp,
    AllocationIcon,
    OverdueIcon,
    AllIcon,
    ActiveIcon,
    InActiveIcon,
    AvailableIcon,
    AddRecharge,
    DeleteIcon2,
    GroupIcon,
    SearchCard
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      customData: "",
      deleteData: {},
      modelName: "",
      items: [
        { id: 1, selected: false },
        { id: 2, selected: false },
        { id: 3, selected: false }
      ],
      bulkMenu: [
        {
          key: "group",
          icon: GroupIcon,
          tooltip: "Bulk Group Update"
        },
        {
          key: "allocation",
          icon: AllocationIcon,
          tooltip: "Bulk Allocation Sims"
        },
        {
          key: "active",
          icon: ActiveIcon,
          tooltip: "Bulk Active Status"
        },
        {
          key: "inactive",
          icon: InActiveIcon,
          tooltip: "Bulk Inactive Status"
        },
        {
          key: "recharge",
          icon: RechargeIcon,
          tooltip: "Bulk Sims Recharge"
        },
        {
          key: "delete",
          icon: DeleteIcon2,
          tooltip: "Bulk Delete Sims"
        }
        // {
        //   key: "overdue",
        //   icon: OverdueIcon
        // }
        // {
        //   key: "delete",
        //   icon: Delete34Icon
        // }
      ],
      tableColumns: [
        { key: "select" },
        {
          key: "msisdn",
          tdClass: "first-child",
          thClass: "first-child text-left",
          label: this.$t("sim.msisdn")
        },

        {
          key: "operator",
          tdClass: "text-left",
          thClass: "text-left",
          sortable: true,
          label: this.$t("sim.Operator")
        },
        {
          key: "recharge",
          tdClass: "text-left Recharge-w",
          thClass: "text-left Recharge-w",
          label: this.$t("sim.Recharge")
        },
        {
          key: "allocated",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("sim.allocated")
        },
        {
          key: "expiry_date",
          tdClass: "text-left dueDate-w",
          thClass: "text-left dueDate-w",
          label: this.$t("sim.DueDate")
        },
        {
          key: "labels",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("sim.Labels")
        },
        {
          key: "status",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("sim.Status")
        },
        {
          key: "ACTIONS",
          tdClass: "operator-action-width",
          thClass: "text-right",
          label: this.$t("sim.Actions")
        }
      ],
      totalSims: 0,
      currentPerPage: this.nearestPage(
        ["10", "20", "30"],
        parseInt(window.innerHeight / 71)
      ),

      activeClass: "active",
      items: [],
      rechargeModelName: "add_bulk_recharge",
      selectedRow: {},
      removedAlertData: {},
      show: false,
      notifications: [],
      totalNotifications: 0,
      defaultBadgeShow: 2,
      selectedItems: 0,
      timeoutId: null,
      items: [],
      allocationData: null,
      isAllocationEnable: false,
      isAllSelected: false,
      selectedSimIds: [],
      searchKey: "",
      allocationData1: []
    };
  },
  props: [
    "searchFiled",
    "groupName",
    "perPage",
    "getSelectedAlert",
    "alertId",
    "isGroupEntity",
    "isAllowChild",
    "selectedAccount"
  ],

  watch: {
    selectedAccount(val) {
      this.currentPage = 1;
      this.debouncegetAllSims();
    },
    perPage(val) {
      if (!this.perPage) {
        this.currentPerPage = 10;
      }
      this.currentPerPage = val;
      const query = Object.assign({}, this.$route.query);

      query.perPage = val;

      this.$router.replace({ query }).catch(() => {});

      this.debouncegetAllSims();
    },
    currentPage() {},
    searchFiled(newVal, oldVal) {
      this.currentPage = 1;
      this.filter = newVal;
      this.debouncegetAllSims();
    },
    isAllowChild() {
      this.debouncegetAllSims();
    },
    "$route.query.status"() {
      this.debouncegetAllSims();
    },
    isAllSelected() {}
  },
  computed: {
    allSelected() {
      return this.items.every((item) => item.selected);
    },
    isAnySelected() {
      return this.items.some((item) => item.selected);
    }
  },
  methods: {
    selectedLabel(e) {
      this.bulkGroup(e);
      this.searchKey = "";
    },

    async allocatedAccount(account) {
      try {
        this.showLoading = true;
        this.allocationData1 = this.items
          .filter((item) => item.selected)
          .map((item) => {
            return {
              sim_id: item.id,
              old_sim_owner_id: item.allocated_id,
              account_id: account
            };
          });
        let req = {
          action: "allocation",
          allSelected: this.isAllSelected,
          allocations: this.allocationData1
        };

        let response = await new DeviceService().performSimOperations(req);
        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("sim.ReallocatedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.reloadApi();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    searchCardClose(e) {
      this.searchKey = "";
    },
    bulkChange(e) {
      this.modelName = e;
      const selectedIds = this.items
        .filter((item) => item.selected) // Filter items where selected is true
        .map((item) => item.id); // Map to extract the id values

      setTimeout(() => {
        if (e === "recharge") {
          this.openEditHistory({ ids: selectedIds });
        } else if (e === "allocation") {
          this.searchKey = "allocation";
        } else if (e === "delete") {
          this.modelName = "delete";
          this.deleteSelectedSims({ ids: selectedIds });
          // this.customData = {
          //   icon: "InfoIcon",
          //   msgTxt: "Do you really want to update sims status is",
          //   lastTxt: "Active",
          //   btnTxt: "Cancel",

          //   btnTxtRemove: "Update"
          // };
        } else if (e === "active") {
          this.updateStatusSelectedSims({ ids: selectedIds });

          this.customData = {
            icon: "InfoIcon",
            msgTxt: "Do you really want to update sims status is",
            lastTxt: "Active",
            btnTxt: "Cancel",
            btnTxtRemove: "Update"
          };
          if (this.getActiveInactiveCount(e)) {
            this.customData.msgTxt =
              "The status cannot be updated because it is already set to ";
            this.customData.isQuite = true;
          }
        } else if (e === "inactive") {
          this.updateStatusSelectedSims({ ids: selectedIds });
          this.customData = {
            icon: "InfoIcon",
            msgTxt: "Do you really want to update sims status is",
            lastTxt: "InActive",
            btnTxt: "Cancel",

            btnTxtRemove: "Update"
          };
          if (this.getActiveInactiveCount(e)) {
            this.customData.msgTxt =
              "The status cannot be updated because it is already set to ";
            this.customData.isQuite = true;
          }
        } else if (e === "group") {
          this.searchKey = "group";
        }
      }, 100);
      console.log(e);
    },
    openEditHistory(item, ids) {
      ids = item.id || item.ids;
      this.selectedRow = { sim_id: ids };
      this.$bvModal.show(this.rechargeModelName);
    },
    async refreshed() {
      this.selectedRow = {};
      this.$bvModal.hide(this.rechargeModelName);
      this.debouncegetAllSims();
    },
    handleOperations(props) {
      if (
        this.modelName === "active" ||
        this.modelName === "inactive" ||
        this.modelName === "recharge"
      ) {
        this.updateStatus(props);
      } else if (this.modelName === "delete") {
        this.removeSim(props, true);
      } else if (this.modelName === "delete_one") {
        this.removeSim(props, false);
      }
    },
    async bulkRecharge(props) {
      try {
        this.showLoading = true;
        let req = {
          simIds: this.items.filter((item) => item.selected).map((e) => e.id),
          action: "recharge",
          status: this.modelName,
          allSelected: this.isAllSelected
        };

        let response = await new DeviceService().performSimOperations(req);
        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("sim.RechargeAddedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.reloadApi();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    reloadApi() {
      this.debouncegetAllSims();
      this.$emit("getDashboard", true);
      this.$bvModal.hide(this.modelName);
      this.isAllSelected = false;
    },
    async updateStatus(props) {
      try {
        this.showLoading = true;
        let req = {
          ids: this.items.filter((item) => item.selected).map((e) => e.id),
          action: "status",
          status: this.modelName,
          allSelected: this.isAllSelected
        };

        let response = await new DeviceService().performSimOperations(req);
        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("sim.StatusUpdatedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.reloadApi();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    async bulkGroup(props) {
      try {
        this.showLoading = true;
        let req = {
          ids: this.items.filter((item) => item.selected).map((e) => e.id),
          action: "group",
          allSelected: this.isAllSelected,
          labels: props
        };

        let response = await new DeviceService().performSimOperations(req);
        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("sim.DeletedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.reloadApi();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    async removeSim(props, isBulk) {
      try {
        this.showLoading = true;
        let req = { sim_id: props.id };
        if (isBulk) {
          req = {
            ids: this.items.filter((item) => item.selected).map((e) => e.id),
            allSelected: this.isAllSelected,
            account_id:
              this.selectedAccount || localStorage.getItem("USER_ACCOUNT_ID")
          };
        }
        let response = await new DeviceService().removeSim(req);
        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("sim.DeletedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$bvModal.hide(this.modelName);
          this.debouncegetAllSims();
          this.$emit("getDashboard", true);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },

    showPopUp(props) {
      this.allocationData = props;
      this.allocationData.allocatedToId = props.allocated_id;
      this.isAllocationEnable = true;
      setTimeout(() => {
        this.$bvModal.show("unit-allocate");
      }, 100);

      // this.allocationData["allocatedToId"] =
      //   this.allocationData["allocated_to_id"];
    },
    deleteSim(props) {
      this.modelName = "delete_one";

      this.deleteData = {
        name: props.msisdn,
        id: props.id
      };
      this.customData = null;
      setTimeout((e) => {
        this.$bvModal.show(this.modelName);
      }, 100);
    },
    updateStatusSelectedSims() {
      this.$bvModal.show(this.modelName);
      this.deleteData = {
        id: ""
      };
      this.customData = {
        msgTxt: this.$t("sim.AreYouSureYouWantToUpdateAllSelectedSims")
      };
    },
    deleteSelectedSims() {
      this.$bvModal.show(this.modelName);
      this.deleteData = {
        id: ""
      };
      this.customData = {
        msgTxt: this.$t("sim.AreYouSureYouWantToDeleteAllSelectedSims")
      };
    },
    debouncegetAllSims(status) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllSims(status);
      }, 300); // Adjust the delay as necessary
    },
    toggleAllSelection() {
      this.items = this.items.map((item) => {
        item.selected = this.isAllSelected;
        return item;
      });
      this.selectedItems = this.items.filter((item) => item.selected).length;
    },
    getActiveInactiveCount(status) {
      if (["active", "inactive"].includes(status)) {
        const selectedStatus = this.items.filter(
          (e) => e.status === status.toUpperCase() && e.selected
        );
        if (selectedStatus.length === this.selectedItems) {
          return "disabled";
        }
      }
      return "";
    },

    async getAllSims(status) {
      try {
        this.show = true;
        const me = this;
        this.filter = [];
        const isOverDue = this.$route.query.status === "overdue" ? true : false;
        if (
          this.$route.query.status &&
          !["all", "overdue"].includes(this.$route.query.status)
        ) {
          let status = this.$route.query.status.toUpperCase();
          if (status == "AVAILABLE") {
            status = "ACTIVE";
          }

          this.filter.push({
            field: "status",
            value: [status],
            operator: "IN"
          });
        }
        if (!this.filter || !this.filter.value) {
          if (this.$route.query.filter) {
            this.filter.push({
              field: this.$route.query.field || "all",
              operator: "ilike",
              value:
                this.$route.query.field == "status"
                  ? this.$route.query.filter.toUpperCase()
                  : this.$route.query.filter
            });
          }
        } else if (this.filter && this.filter.value) {
          if (this.$route.query.filter) {
            this.filter.push({
              field: this.$route.query.field || "all",
              operator: "ilike",
              value:
                this.$route.query.field == "status"
                  ? this.filter.value.toUpperCase()
                  : this.filter.value
            });
          }
        }
        const query = Object.assign({}, this.$route.query);
        let requestData = {
          is_overdue: isOverDue,
          page: query.page ? Number(query.page) : this.currentPage,
          page_size: query.pageSize
            ? Number(query.pageSize)
            : parseInt(this.currentPerPage),
          is_allow_child: this.isAllowChild,
          account_id:
            this.selectedAccount || localStorage.getItem("USER_ACCOUNT_ID"),
          filters: this.filter
        };
        if (this.groupName) {
          requestData.filters = [
            {
              field: "labels",
              operator: "ilike",
              value: this.groupName
            }
          ];
        }

        let response = await new DeviceService().getSims(requestData);

        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            if (this.allSelected) {
              u.selected = this.allSelected;
            } else {
              u.selected = false;
            }

            u.allocated = u.allocated_to;
            u.status = u.status && u.status.toUpperCase();
            const day = Math.floor(Math.random() * 100) + 1;
            u.expiry_date =
              u.expiry_date &&
              this.calculateDueStatus(u.recharge_date, u.expiry_date);
            return u;
          });

          this.totalSims =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      this.showLabelSize();
    },
    updateSelected(item) {
      if (!item.selected) {
        this.isAllSelected = false;
      } else {
        const isAllSelected = this.items.find((e) => !e.selected);
        if (!isAllSelected || !isAllSelected.id) {
          this.isAllSelected = true;
        }
      }
      this.selectedItems = this.items.filter((item) => item.selected).length;
    },
    onRowSelected(selectedRows) {
      console.log("Selected rows:", selectedRows);
    },
    showLabelSize() {
      const parentElement = document.querySelector(".parent");
      if (parentElement) {
        const parentWidth = parentElement.offsetWidth;
        this.defaultBadgeShow = Math.floor(parentWidth / 71);
      }
    },
    async changeInput(allocationData) {
      this.allocationData = allocationData;
    },
    onClose() {
      this.$bvModal.hide(this.modelName);
      this.isAllocationEnable = false;
      // this.debouncegetAllSims();
    },
    close(data) {},
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.id === this.alertId) return "table-primary-light";
    },
    handleRowClick(e) {
      if (this.$route.name === "notification-create") {
        this.getSelectedAlert(e);
      }
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    redirectViewScreen(row) {
      this.$router.push({
        name: "sim-info",
        params: { id: row.item.id }
      });
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debouncegetAllSims();
    },

    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, format);
    }
  },

  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debouncegetAllSims();
    });

    if (this.isGroupEntity) {
      this.debouncegetAllSims();
    }
    window.addEventListener("resize", this.showLabelSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.showLabelSize);
  }
};
</script>
<style lang="scss">
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}
.group-width-right {
  max-width: 200px;
  width: 200px;
  min-width: 200px;
  text-align: right !important;
}
.group-width {
  text-align: right;
}
.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}
.ml-10 {
  margin-left: 10px;
}
.b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      top: -1px !important;
    }
  }
}
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}
</style>
<style lang="scss">
.simlist-view-tab {
  .b-table-sticky-header {
    max-height: calc(100vh - 390px) !important;
    min-height: calc(100vh - 390px) !important;
    overflow-y: auto;
  }
  .table-responsive {
    max-height: calc(100vh - 390px) !important;
    min-height: calc(100vh - 390px) !important;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 540px) {
  .edit-card-w {
    width: 100% !important;
    background-color: red;
    flex-direction: column;
  }
  .mb-b23 {
    margin-bottom: 10px;
  }
  .simlist-view-tab {
    .b-table-sticky-header {
      max-height: calc(100vh - 522px) !important;
      min-height: calc(100vh - 522px) !important;
      overflow-y: auto;
    }
    .table-responsive {
      max-height: calc(100vh - 522px) !important;
      min-height: calc(100vh - 522px) !important;
      overflow-y: auto;
    }
  }
}
.min-w {
  min-width: 140px;
}
.Recharge-w {
  min-width: 316px !important;
}
.dueDate-w {
  min-width: 240px !important;
}
.edit-icon {
  border-radius: 7px !important;
}
.edit-card-w {
  width: 100%;
  max-width: 500px !important;
  background: #fff;
  border-radius: 0.3125rem;
  box-shadow: 0px 4px 24px 0px rgba(34, 41, 47, 0.1);
  padding: 7px;
}
.dark-layout {
  .edit-card-w {
    background: #161d32;
  }
}
.disabled {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
</style>
